import React, {
    useEffect,
    useState
} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import RenderMarkdown from './RenderMarkdown';
import { TabPanel, a11yProps } from './TabPanel';
import './TabManagement.css'

export default function TabManagement(props) {

    const { tabs, tail } = props;

    const handleChange = (e, newValue) => {
        e.preventDefault();
        window.location.hash = tabs[newValue].hash;
        setValue(newValue);
    };

    let hashIndex = tabs.indexOf(tabs.find(element => element.hash === window.location.hash));

    const [value, setValue] = useState(hashIndex < 0 ? 0 : hashIndex);
    const [markdown, updateMarkdown] = useState("")

    useEffect(() => {
        import(`./markdown/${tabs[value].file}`)
            .then(res => {
                fetch(res.default)
                    .then((res) => res.text())
                    .then((text) => updateMarkdown(text))
                    .catch(err => console.log(err));
            })
            .catch(err => console.log(err));

    }, [tabs, value]);

    return (
        <>
            <Box>
                <Tabs value={value} onChange={handleChange} aria-label="documentation tabs" variant="scrollable" allowScrollButtonsMobile indicatorColor="secondary">
                    {
                        tabs.map((tab, i) => {
                            return <Tab key={i} label={tab.title} {...a11yProps({ i })} disabled={tab.disabled ? true : false} />
                        })
                    }
                </Tabs>
            </Box>
            {
                tabs.map((tab, i) => {
                    return (
                        <TabPanel key={i} component={'div'} value={value} index={i}>
                            {
                                value === i ? <RenderMarkdown markdown={markdown} />
                                    : <p>Please select the tab you want to display...</p>
                            }
                            {
                                tab.hash === '#getting-started' ? tail
                                    : ""
                            }
                        </TabPanel>
                    )
                })
            }
        </>
    )
}
