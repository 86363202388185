import React from 'react'
import Container from '@mui/material/Container'
import Breadcrumb from './Breadcrumb'
import Footer from './Footer';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MUIlink from '@mui/material/Link';
import { Link } from 'react-router-dom';

import './Product.css'
import './ProductMikey.css'
import { Grid } from '@mui/material';

const steps = [
    {
      label: 'Install a Cartrack terminal in your vehicles',
      description: `The terminal is installed in your vehicle in a few minutes and allows us to communicate from a distance. Immediately, you benefit from a wide range of services with Cartrack's platform.`,
    },
    {
      label: 'Manage your vehicle\'s keys in your app (Android/iOS SDK)',
      description: 'Cartrack\'s Software Development Kit communicates securely with the vehicle and allows the users to send several commands, including Lock and Unlock. The user must be within 30 meters radius of the vehicle.',
    },
    {
      label: 'Sit down, relax, manage your fleet remotely',
      description: `You can now allow/disallow users to access vehicles based on your business's needs.`,
    },
  ];

export default function ProductMikey() {

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
    setActiveStep(0);
    };

    const links = [
        {name: 'Mikey', href: '/mikey'}
    ]

    document.title = 'Cartrack Mikey' 

    return (
        <div className="main">
        <Breadcrumb links={links} />
        <Container className="mainPages">
            <Container maxWidth="md" style={{ marginTop: '60px'}}>
                <h1>Mikey</h1>
                <Grid container spacing={4} direction="row">
                    <Grid item xs={12} md={6}>
                        <p>Avoid the hassle of managing keys for your fleet. We made it easy.</p>
                        <p>Let users unlock the car with their smartphone, and you manage accesses remotely.</p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img src="/images/car-keys.jpeg" alt="Traditional car keys" width="50%" style={{borderRadius: "4px"}} />
                    </Grid>
                </Grid><br />
                
                <h2>How it works</h2>
                
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel>
                                {step.label}
                            </StepLabel>
                            <StepContent style={{marginLeft: '32px'}}>
                            <Typography>{step.description}</Typography>
                                <Box sx={{ mb: 2 }}>
                                    <div><br/>
                                        <Button
                                            color="default"
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            {index === steps.length - 1 ? 'That\'s it !' : 'Next'}
                                        </Button>
                                        <Button
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Back
                                        </Button>
                                    </div>
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length && (
                    <Paper square elevation={0} sx={{ p: 3 }}>
                        <p>
                            <MUIlink color="primary" underline="hover" onClick={handleReset} style={{cursor: 'pointer', color: '#c3c3c3'}}>Reset</MUIlink>
                        </p>
                    </Paper>
                )}

            <h2>Getting Started</h2>
            <Typography>Check out our Android and iOS SDK documentation:</Typography>
            <ul>
                <li><MUIlink color="secondary" underline="hover" href="https://developer.cartrack.com/mikey-android/getStarted.html">Android SDK</MUIlink></li>
                <li><MUIlink color="secondary" underline="hover" href="https://developer.cartrack.com/mikey-ios/index.html">iOS SDK</MUIlink></li>
            </ul>
            <Typography>Our Fleet API documentation for remote controls and more:</Typography>
            <ul>
                <li>
                    <MUIlink component={Link} color="secondary" underline="hover" to="/fleet-api">Fleet API</MUIlink>
                </li>
            </ul>
            <p>Get access to the information you need in real-time:</p>
            <ul>
                <li>Central locking system status</li>
                <li>Fuel or EV battery percentage</li>
                <li>Geolocation of the vehicle</li>
                <li>Odometer</li>
                <li>And more...</li>
            </ul><br />
            <Button 
                color="secondary" 
                variant="contained"
                href="mailto:info@cartrack.sg?subject=Contact%20Cartrack%20Develpment&amp;body=--%20Your%20message%20here%20--%0A%0ASent%20from%20development.cartrack.com" target="_blank"
            >Contact Us</Button>
            </Container>
        </Container>
        <Footer />
        </div>
    )
}
