import React from 'react'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Container from '@mui/material/Container';
import HomeCard from './HomeCard';
import Footer from './Footer';
import './Home.css';

const Hero = () => {
    
    return(
      <div className="hero">
          <Grid container rowSpacing={1} justifyContent="center" direction="row">
              <Grid className="hero-icon" item xs={12} md={1}>
                    <img alt="Shell Icon" width={80} src="/images/svg-code.svg" />
              </Grid>
              <Grid className="hero-text" item xs={12} md={4}>
                    <h1>Develop with Cartrack</h1>
              </Grid>
          </Grid>
      </div>
    )
  }

export default function Home() {

    document.title = 'Cartrack for Developers' 
    
    return (
        <div className="main">
            <AppBar position="fixed">
                <Container maxWidth="xl">
                    <Toolbar>
                        <Grid container justifyContent="center">
                            <Grid item xs={10}>
                                <img alt="Cartrack Logo" width={130} src="/cartrack-menu-logo-white.svg" />
                            </Grid>
                            <Grid item xs={2} style={{textAlign: 'right'}}>
                                <Button 
                                    // color="white" 
                                    sx={{color: "#ffffff"}}
                                    // variant="contained"
                                    href="mailto:fleet-api@cartrack.com?subject=Contact%20Cartrack%20Developer&amp;body=--%20Your%20message%20here%20--%0A%0ASent%20from%20developer.cartrack.com" target="_blank"
                                >
                                    <ContactSupportIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </Container>
            </AppBar>
            <Hero name='hero' />
            <Container maxWidth="xl">
                <Grid container sx={{mt: 1, mb: 3}} spacing={2} direction="row" justifyContent="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <HomeCard
                            service="WEBSERVICE"
                            title="Fleet API"
                            subtitle="For you to interact with your fleet data"
                            description="Integrate Fleetweb services in your solution with this set of APIs. Access your vehicle's, driver's data and more."
                            href="/fleet-api"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <HomeCard
                            service="WEBSERVICE"
                            title="Telematics API"
                            subtitle="For fleets with a non-Cartrack tracker"
                            description="Are you not using our tracker? Connect your vehicles to our platform and benefit from our fleet management services!"
                            external="true"
                            href="https://development.cartrack.com/telematics"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <HomeCard
                            service="SDK"
                            title="Mikey"
                            subtitle="A secure and simple keyless entry solution"
                            description="Cartrack is in business to help you feel safe renting out your vehicles by providing accurate positioning or services that interact with the car itself. You control remotely who can unlock your cars... and more!"
                            href="/mikey"
                        />
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </div>
    )
}
