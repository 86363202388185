import React from 'react'
import './Footer.css'

export default function Footer() {

    const year = new Date().getFullYear()

    return (
        <div className="footer">
        <div className="innerFooter">
            <img 
                src='/images/logo_cartrack.png'
                height={20}
                width={20}
                style={{verticalAlign: 'center'}}
                alt='Cartrack logo'
                />
            <span style={{verticalAlign: 'center', marginTop: 2}}>© 2004 - {year} Cartrack - All Rights Reserved </span>
        </div>
    </div>
    )
}
