// Flags obtained from "Twitter" format here https://emojipedia.org/flag-cambodia/
const countries = [
    { alpha2: "ao", emoji: "🇦🇴", flag: '/images/flags/flagao.png', name: "Angola" },
    { alpha2: "bw", emoji: "🇧🇼", flag: '/images/flags/flagbw.png', name: "Botswana" },
    { alpha2: "kh", emoji: "🇰🇪", flag: '/images/flags/flagkh.png', name: "Cambodia" },
    { alpha2: "fr", emoji: "🇫🇷", flag: '/images/flags/flagfr.png', name: "France" },
    { alpha2: "hk", emoji: "🇭🇰", flag: '/images/flags/flaghk.png', name: "Hong-Kong" },
    { alpha2: "id", emoji: "🇮🇩", flag: '/images/flags/flagid.png', name: "Indonesia" },
    { alpha2: "ke", emoji: "🇰🇪", flag: '/images/flags/flagke.png', name: "Kenya" },
    { alpha2: "mw", emoji: "🇲🇼", flag: '/images/flags/flagmw.png', name: "Malawi" },
    { alpha2: "my", emoji: "🇲🇾", flag: '/images/flags/flagmy.png', name: "Malaysia" },
    { alpha2: "me", emoji: "🇦🇪", flag: '/images/flags/flagme.png', name: "Middle East" },
    { alpha2: "mz", emoji: "🇲🇿", flag: '/images/flags/flagmz.png', name: "Mozambique" },
    { alpha2: "na", emoji: "🇳🇦", flag: '/images/flags/flagna.png', name: "Namibia" },
    { alpha2: "ng", emoji: "🇳🇿", flag: '/images/flags/flagng.png', name: "Nigeria" },
    { alpha2: "nz", emoji: "🇳🇿", flag: '/images/flags/flagnz.png', name: "New-Zealand" },
    { alpha2: "ph", emoji: "🇵🇭", flag: '/images/flags/flagph.png', name: "Philippines" },
    { alpha2: "pl", emoji: "🇵🇱", flag: '/images/flags/flagpl.png', name: "Poland" },
    { alpha2: "pt", emoji: "🇵🇹", flag: '/images/flags/flagpt.png', name: "Portugal" },
    { alpha2: "rw", emoji: "🇷🇼", flag: '/images/flags/flagrw.png', name: "Rwanda" },
    { alpha2: "sg", emoji: "🇸🇬", flag: '/images/flags/flagsg.png', name: "Singapore" },
    { alpha2: "vn", emoji: "🇻🇳", flag: '/images/flags/flagvn.png', name: "Vietnam" },
    { alpha2: "za", emoji: "🇿🇦", flag: '/images/flags/flagza.png', name: "South Africa" },
    { alpha2: "es", emoji: "🇪🇸", flag: '/images/flags/flages.png', name: "Spain" },
    { alpha2: "sz", emoji: "🇸🇿", flag: '/images/flags/flagsz.png', name: "Swaziland" },
    { alpha2: "tz", emoji: "🇹🇿", flag: '/images/flags/flagtz.png', name: "Tanzania" },
    { alpha2: "th", emoji: "🇹🇭", flag: '/images/flags/flagth.png', name: "Thailand" },
    { alpha2: "us", emoji: "🇺🇸", flag: '/images/flags/flagus.png', name: "USA" },
    { alpha2: "zm", emoji: "🇿🇲", flag: '/images/flags/flagzm.png', name: "Zambia" },
    { alpha2: "zw", emoji: "🇿🇼", flag: '/images/flags/flagzw.png', name: "Zimbabwe" },
]

export default countries;