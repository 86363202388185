import React from 'react'
import ProductTabs from './ProductTabs'
import ProductFleetAPIList from './ProductFleetAPIList'

export default function ProductFleetAPI(props) {

    let title = 'Fleet API'

    const links = [
        { name: 'Fleet API', href: '/fleet-api/' }
    ]


    const tabs = [
        { title: "Getting started", file: 'fleetapi-getting-started.md', hash: "#getting-started", tail: true }
    ]

    const tail = <ProductFleetAPIList />

    return <ProductTabs title={title} links={links} tabs={tabs} tail={tail} debug={true} />
}
