import React from 'react';
import Container from '@mui/material/Container';
import Breadcrumb from './Breadcrumb';
import TabManagement from './TabManagement';

import './Product.css'

export default function ProductTabs(props) {

    const { title, links, tabs, tail } = props;
    
    document.title = `${title} Docs`

    return (
        <div className="main">
            <Breadcrumb links={links} />
            <Container className="mainPages">
                <Container maxWidth="md" style={{ marginTop: '60px'}}>
                    <h1>{title}</h1>
                    <TabManagement tabs={tabs} tail={ tail ? tail : ''} />
                </Container>
            </Container>
        </div>
    )
}
