import React from 'react'
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import WebhookOutlinedIcon from '@mui/icons-material/WebhookOutlined';
import AllInboxOutlinedIcon from '@mui/icons-material/AllInboxOutlined';
import DeveloperModeOutlinedIcon from '@mui/icons-material/DeveloperModeOutlined';
import { UilBracketsCurly} from '@iconscout/react-unicons';

import './HomeCard.css'

export default function HomeCard(props) {

    const { service, title, subtitle, description, href, external } = props;

    const selectIcon = () => {
        if(service === 'WEBSERVICE' && title === 'Webhooks') {
            return <WebhookOutlinedIcon sx={{ color: '#f47735' }} />
        } else if(service === 'WEBSERVICE' && title === 'RabbitMQ Messaging')  {
            return <AllInboxOutlinedIcon sx={{ color: '#f47735' }} />
        } else if(title === 'Mikey')  {
            return <DeveloperModeOutlinedIcon sx={{ color: '#f47735' }} />
        } else if(service === 'WEBSERVICE')  {
            return  <UilBracketsCurly style={{ color: '#f47735' }} />
        } else {
            return ''
        }
    }

    const icon = selectIcon();

    return (
        <div>
            <Card sx={{ minWidth: 275 }}>
                <CardContent>
                    <Typography component={'span'} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {/* <span>{service}</span> */}
                    </Typography>
                    <Typography variant="h5" component="div">
                        {icon}{' '}
                        {title}
                    </Typography>
                    <Typography className="subtitle" sx={{ mb: 1.5 }} color="text.secondary">
                        {subtitle}
                    </Typography>
                    <Typography className="description" sx={{ mb: 1.5 }} variant="body2" minHeight={80}>
                        {description}
                    </Typography>
                </CardContent>
                <CardActions>
                        {
                            external === "true" ?
                            <Button  
                                href={href}
                                color="secondary" 
                                size="medium">
                                Learn More
                            </Button>
                            : 
                            <Button 
                            component={Link} 
                            to={href} 
                            color="secondary" 
                            size="medium">Learn More</Button>
                        }
                </CardActions>
            </Card>
        </div>
    )
}
