import React, {
    useEffect
} from 'react'
import { useParams } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';

export default function RedocPage() {

    let { country } = useParams();

    let title = `Fleet API - ${country.toUpperCase()}`;

    const links = [
        {name: 'Fleet API', href: '/fleet-api/'},
        {name: `${title}`, href: `/fleet-api/${country}`},
    ]

    document.title = title; 
    
    useEffect(() => {
        window.location.href = `https://fleetapi-${country}.cartrack.com/rest/redoc.php`;
      }, [country]);

    return (
        <>
            <Breadcrumb links={links} />
            <iframe title={`${country} Redoc`} src={`https://fleetapi-${country}.cartrack.com/rest/redoc.php`} width="100%" height="3000px" style={{border: "none"}}></iframe>
        </>
    )
}
