import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

function flatten(text, child) {
    return typeof child === 'string'
      ? text + child
      : React.Children.toArray(child.props.children).reduce(flatten, text)
}
  
function HeadingRenderer(props) {
    var children = React.Children.toArray(props.children)
    var text = children.reduce(flatten, '')
    var slug = text.toLowerCase().replace(/\W/g, '-')
    var newLevel = (props.level + 2);
    return React.createElement('h' + newLevel, {id: slug}, props.children)
}

export default function RenderMarkdown(props) {

    const { markdown } = props;

    return (
        <ReactMarkdown 
            children={markdown} 
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            components={{
                code({node, inline, className, children, ...props}) {
                const match = /language-(\w+)/.exec(className || '')
                return !inline && match ? (
                    <SyntaxHighlighter
                        children={String(children).replace(/\n$/, '')}
                        style={a11yDark}
                        language={match[1]}
                        PreTag="div"
                        {...props}
                    />
                ) : (
                    <code style={{fontFamily: 'Arial', wordSpacing: "1px"}} className={className} {...props}>
                        {children}
                    </code>
                )
                },
                a({node, ...props}) {
                    let url = ""
                    if(props.href.substring(0,7) === '_target'){
                        url = <a href={props.href.slice(7,)}>{props.children[0]}</a>
                    } else {
                        url = <a href={props.href} target="_blank" rel="noreferrer">{props.children[0]}</a>
                    };
                    return url
                },
                h1: HeadingRenderer, 
                h2: HeadingRenderer, 
                h3: HeadingRenderer, 
                h4: HeadingRenderer
            }}
        />
    )
}
