import React from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";

export default function Breadcrumb(props) {

    const { links } = props;

    let navigate = useNavigate();

    function handleClick(link) {
        navigate(link);
      }

    return (
        <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 3, ml: 3  }}>
            <Link underline="hover" color="inherit" onClick={() => handleClick('/')} style={{cursor: 'pointer'}}>
                Cartrack Dev
            </Link>
            {
                links.map((link, i) => {

                return <Link
                        key={i}
                        component={Link}
                        underline="hover"
                        color="inherit"
                        style={{cursor: 'pointer'}}
                        onClick={() => handleClick(link.href)}
                    >
                    {link.name}
                    </Link>
                })
            }
      </Breadcrumbs>
    )
}
