import Home from './Home';
import ProductMikey from './ProductMikey';
import ProductFleetAPI from './ProductFleetAPI';
import ScrollToTop from './ScrollToTop';
import RedocPage from './RedocPage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ABCD1234')

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#26282f',
    },
    secondary: {
      main: '#f47735',
      contrastText: 'rgba(255,253,253,0.87)',
    },
    default: {
      main: '#E0E0E0',
      
    }
  },
  typography: {
    fontFamily: 'Montserrat',
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
      <ScrollToTop />
        <Routes>
            <Route path ="/" element={<Home />} />
            <Route path="/mikey-keyless-entry" element={<ProductMikey />} />
            <Route path="/mikey" element={<ProductMikey />} />
            <Route path="/fleet-api" element={<ProductFleetAPI />} />
            <Route path="/fleet-api-staging" element={<ProductFleetAPI debug={true}/>} />
            <Route path="/fleet-api/:country" element={<RedocPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
