import React from 'react'
import Link from '@mui/material/Link';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function ProductFleetAPITable(props) {

    const { countries } = props;

    const baseURL = "https://fleetapi-"
    const endRestfulURL = ".cartrack.com/rest/redoc.php"

    function createData(name, url) {
        return { name, url };
    }
      
    const rows = countries.map((country, i) => {
            
        const url = baseURL + country.alpha2 + endRestfulURL;
    
        return createData(
        <>
            <img alt={`Flag ${country.name} `} style={{verticalAlign: 'middle'}} width={20} src={country.flag} /> {' '}
            <span style={{verticalAlign: 'middle'}}>{country.name}</span>
        </>,
        <Link color="secondary" underline="hover" href={url}>{url}</Link>
        )
    });

    return (
        <TableContainer sx={{mt: 3}} component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="RESTFUL documentation table">
                <TableHead>
                <TableRow>
                    <TableCell align="left">Country</TableCell>
                    <TableCell align="left">RESTFUL documentation</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {rows.map((row, i) => (
                    <TableRow
                        key={i}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell align="left" component="th" scope="row">
                            {row.name}
                        </TableCell>
                        <TableCell align="left">
                            {row.url}
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
