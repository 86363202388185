import React from 'react'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import countries from './utils/countryList';
import ProductFleetAPITable from './ProductFleetAPITable';

export default function ProductFleetAPIList() {

    let [country, setCountry] = React.useState('All');

    const handleChange = (event) => {
        setCountry(event.target.value);
    };

    function isCountry(ct) {
        return ct.alpha2 === country
    }

    function fetchSelection() {
        if( country === 'All' ){
            return countries;
        } else {
            let country = [];
            country[0] = countries.find(isCountry);
            return country
        }
    }

    let selection = fetchSelection();

    return (
        <>
            <Box key='form' component={'div'} sx={{ minWidth: 120, maxWidth: 200, mt: 3 }}>
                <FormControl variant="filled" fullWidth>
                    <InputLabel id="country-select-label">Country</InputLabel>
                    <Select
                        labelId="country-select-label"
                        id="country-select"
                        value={country}
                        label="Country"
                        onChange={handleChange}
                    >
                    <MenuItem value={'All'}>All</MenuItem>
                    {
                        countries.map((country, i) => {

                            return (
                                <MenuItem key={i} value={country.alpha2}>{country.name}</MenuItem>
                            )
                        })
                    }
                    </Select>
                </FormControl>
            </Box>
            <ProductFleetAPITable countries={selection} />
        </>

    ) 
}
